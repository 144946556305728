<template>
  <div
    class="
    ImpersonatingNotice flex flex-wrap items-center justify-center
    p-12 desk:min-h-32 w-full relative z-header
    bg-purplePrimary text-lightest type-xs gap-8 desk:gap-16"
  >
    <div class="flex flex-row flex-wrap gap-8 items-center justify-center">
      {{ $t('siteNotice.loggedInAs', {name: userProfile.company?.name, id: userProfile.company?.code}) }}
      <div class="flex flex-wrap gap-4">
        <button
          class="btn--text btn--sm btn--inv"
          @click="stopImpersonating"
        >
          {{
            userStore.isCustomerSuccessUser ? $t('siteNotice.changeSalon.customerSuccess') : $t('siteNotice.changeSalon')
          }}
        </button>
        <span class="whitespace-nowrap"> / </span>
        <button
          class="btn--text btn--sm btn--inv"
          @click="stopImpersonating"
        >
          {{
            userStore.isCustomerSuccessUser ? $t('impersonateModal.customerSuccess.stopImpersonating') : $t('mypage.customer.stopShopAsCustomer')
          }}
        </button>
      </div>
    </div>
    <div v-if="orderProposalId" class="flex flex-wrap gap-8">
      {{ $t('siteNotice.editingOrderProposal') }} #{{ orderProposalId }}
      <div class="flex flex-wrap gap-4">
        <nuxt-link
          :to="$manualUrl('/')"
          class="btn--text btn--sm btn--inv"
        >
          {{ $t('checkout.orderProposal.addMore') }}
        </nuxt-link>
        <span>/</span>
        <div class="btn--text btn--sm btn--inv" @click="clearBasket()">
          {{ $t('checkout.orderProposal.cancel') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useUiStore } from '~/store/ui';

const userStore = useUserStore();
const cartStore = useCartStore();
const { userProfile } = storeToRefs(userStore);
const { salesUrl, accountUrl } = useLinkReplacer();

const stopImpersonating = async() => {
  if (userStore.isCustomerSuccessUser) {
    await userStore.stopImpersonatingCompany();
    navigateTo(accountUrl('selectCustomer'));
  } else {
    await userStore.stopImpersonatingCompany();
    navigateTo(salesUrl('customers'));
  }
};

const clearBasket = async() => {
  await userStore.setCurrentlyEditingOrderProposal(null);
  cartStore.deleteCart();
  navigateTo(salesUrl('orderProposals'));
};

const orderProposalId = computed(() => {
  return userStore.isEditingOrderProposal ? userStore.userProfile.currentlyEditingOrderProposal?.norceBasketId : null;
});
</script>

<style lang="postcss">
.truncate-p > p {
  @apply truncate text-10 desk:text-12 leading-single;
}
.usp-text {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.usp-text.active {
  opacity: 1;
}
</style>
